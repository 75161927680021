exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-contentful-news-slug-tsx": () => import("./../../../src/pages/news/{ContentfulNews.slug}.tsx" /* webpackChunkName: "component---src-pages-news-contentful-news-slug-tsx" */),
  "component---src-pages-service-contentful-service-slug-tsx": () => import("./../../../src/pages/service/{ContentfulService.slug}.tsx" /* webpackChunkName: "component---src-pages-service-contentful-service-slug-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "slice---src-components-slices-site-footer-index-tsx": () => import("./../../../src/components/slices/site-footer/index.tsx" /* webpackChunkName: "slice---src-components-slices-site-footer-index-tsx" */),
  "slice---src-components-slices-site-header-index-tsx": () => import("./../../../src/components/slices/site-header/index.tsx" /* webpackChunkName: "slice---src-components-slices-site-header-index-tsx" */),
  "slice---src-components-slices-site-navigation-index-tsx": () => import("./../../../src/components/slices/site-navigation/index.tsx" /* webpackChunkName: "slice---src-components-slices-site-navigation-index-tsx" */)
}


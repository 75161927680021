import { Slice, type PageProps } from 'gatsby';
import { useEffect, type FC } from 'react';
import useScrollbarSize from 'react-scrollbar-size';
import { twMerge } from 'tailwind-merge';

// NOTE:
// cssでgridのサイズ計算に100vwを使用しているため、
// OSなどでスクロールバーを常に表示する設定をしていると、
// gridがズレてしまうので、スクロールバーの幅を取得して、
// css変数にセットする。それをcssで100vwから引いている。
const useSetScrollbarWidth = () => {
  const { width } = useScrollbarSize();
  useEffect(() => {
    if (document) {
      document.documentElement.style.setProperty(
        '--scrollbar-width',
        `${width}px`
      );
    }
  }, [width]);
};

const Layout: FC<PageProps> = ({ location, children }) => {
  useSetScrollbarWidth();
  // NOTE:
  // gatsby-plugin-offlineを使用すると、
  // swインストール後、一旦fallbackページを経由する場合があり、
  // その際hydrationエラーが発生するため
  // fallbackページの場合はnullを返す
  // エラー内容
  // https://reactjs.org/docs/error-decoder.html/?invariant=418
  // https://reactjs.org/docs/error-decoder.html/?invariant=423
  if (location.pathname === '/offline-plugin-app-shell-fallback/') {
    return null;
  }
  return (
    <div className={twMerge('grid', 'items-start', 'layout-grid-cols')}>
      <Slice
        alias="site-header"
        className={twMerge('sticky', 'top-0', 'min-h-screen', 'col-span-1')}
        pathname={location.pathname}
      />
      <div className={twMerge('col-[2_/_-1]')}>
        <Slice alias="site-navigation" pathname={location.pathname} />
        <main>{children}</main>
        <Slice alias="site-footer" />
      </div>
    </div>
  );
};
export default Layout;

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"狛江湯","short_name":"狛江湯","start_url":"/","background_color":"#ffffff","theme_color":"#7B8F80","display":"minimal-ui","icon":"src/images/icon.png","description":"黄金湯は戦前1932年墨田区太平の地にて創業いたしました。それ以来、1世紀近くのあいだ、地元の人々の公衆衛生を担う銭湯を営んでおります。東京の下町エリアを代表する銭湯「大黒湯」は姉妹店。大黒湯にて日本初のオールナイト営業や、スカイツリーを望む大型の露天風呂など、今までにない挑戦を続けてきた、新保夫婦が新たな銭湯のかたちを追い求め、2020年にフルリニューアルいたしました。","lang":"ja","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"47dd6608777bf96ac832c74492e399e7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5KVDNH4","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
